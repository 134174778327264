import { isValid } from 'date-fns';

export function parseDate<T, TFallback>(dateValue: T, fallback: TFallback): Date | TFallback {
    if (dateValue) {
        if (typeof dateValue === 'string') {
            const date = new Date(dateValue);
            if (isValid(date)) {
                return date;
            }
        } else if (dateValue instanceof Date) {
            return dateValue;
        }
    }

    // dateValue is neither a string nor a date, so return the existing value
    return fallback;
}
