import { ServiceConfig } from '../../models/ServiceConfig';
import Methods from '../Methods';

export class ConfigClient {
    private methods: Methods;

    constructor(methods: Methods) {
        this.methods = methods;
    }

    async getConfig(): Promise<ServiceConfig> {
        const response = await this.methods.get(`config`);
        const json = await response.json();
        return new ServiceConfig(json.usesClarityCompanies);
    }
}

export default ConfigClient;
