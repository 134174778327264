import React from 'react';

export interface PatienceProps {
    showPatience: boolean;
    children: React.ReactNode;
}

export const Patience: React.FC<PatienceProps> = ({ showPatience, children }): React.ReactElement => {
    if (showPatience) {
        return (
            <div className="hcon">
                <div className="hc">Loading...</div>
                <div className="opacity-0">{children}</div>
            </div>
        );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default Patience;
