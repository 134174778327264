import { Company } from '../../models/Company';
import Methods from '../Methods';

export class CompanyClient {
    private methods: Methods;

    constructor(methods: Methods) {
        this.methods = methods;
    }

    async listCompanies(): Promise<Company[]> {
        const response = await this.methods.get(`company`);
        const json = await response.json();
        const companies = new Array<Company>();

        if (Array.isArray(json)) {
            (json as Array<unknown>).forEach((company) => {
                companies.push(Company.fromJson(company));
            });
        }

        return companies;
    }
}

export default CompanyClient;
