import { BehaviorSubject } from 'rxjs';

// Creating subjects to mutate data over time
const tokenSubject = new BehaviorSubject<string | null>(null);

// Defining observables from subjects to being able to subscribe to change updates.
export const userToken$ = tokenSubject.asObservable();

/**
 *
 * Updates the user token data subject by triggering the observer next() method.
 * Must have been subscribed to the userToken$ observable to listen to changes.
 * @param token - receives a string or a null assignment
 */
export const emitUserToken = (token: string | null): void => tokenSubject.next(token);
