/* eslint-disable no-console */
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthenticationConfig } from '../../contexts/AuthenticationConfigContext/AuthenticationConfigContext';
import LogoutWarningModal from '../LogoutWarningModal/LoginWarningModal';
import { OktaAuthManager } from '../../utilities/OktaAuthManager/OktaAuthManager';

/**
 * After this duration, the user is considered inactive.
 */
const INACTIVE_TIMEOUT_MS = 1000 * 60 * 5; // 5 minutes
const DEFAULT_RENEWAL_PRIOR_TO_EXPIRATION_SECONDS = 60; // 1 minute

export const UserActivityWatcher: React.FC = () => {
    const { renewalPriorToExpirationSeconds, showLogoutWarning: isWarningModalEnabled } = useAuthenticationConfig();
    const { authState } = useOktaAuth();

    const lastActiveAt = React.useRef(new Date());
    const [showWarning, setShowWarning] = React.useState(false);

    const onWarnExpiration = (): void => {
        const isUserInactive = new Date().getTime() - lastActiveAt.current.getTime() > INACTIVE_TIMEOUT_MS;
        console.debug(
            `UserActivityWatcher: token is expiring soon, the user is ${isUserInactive ? 'inactive' : 'active'}`,
        );

        if (isUserInactive) setShowWarning(true);
        else OktaAuthManager.renewTokens();
    };

    const onUserActive = (): void => {
        const inactiveSince = lastActiveAt.current;
        lastActiveAt.current = new Date();

        const hasBeenInactiveTooLong = inactiveSince.getTime() + INACTIVE_TIMEOUT_MS < new Date().getTime();

        // if the warning is showing, hide it... unless its been too long since the user was last active, then expect the manager to automatically logout
        if (showWarning && !hasBeenInactiveTooLong) {
            console.debug('UserActivityWatcher: user is active, hiding warning and renewing tokens');

            setShowWarning(false);
            OktaAuthManager.renewTokens();
        }
    };

    useIdleTimer({
        onAction: onUserActive,
        crossTab: true,
    });

    // begin the expiration timer
    const expirationTime = authState?.accessToken?.expiresAt;
    React.useEffect(() => {
        if (expirationTime == null) return undefined;

        const now = Date.now();
        const { expireEarlySeconds } = OktaAuthManager.configuration;
        const msUntilExpiration = expirationTime * 1000 - now - expireEarlySeconds * 1000;
        const msUntilWarning =
            msUntilExpiration - (renewalPriorToExpirationSeconds || DEFAULT_RENEWAL_PRIOR_TO_EXPIRATION_SECONDS) * 1000;

        console.debug('UserActivityWatcher: token will expire at', new Date(expirationTime * 1000));
        console.debug('UserActivityWatcher: if inactive, user will be warned at', new Date(now + msUntilWarning));

        const warnTimeout = setTimeout(onWarnExpiration, msUntilWarning);
        return () => clearTimeout(warnTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expirationTime, renewalPriorToExpirationSeconds]);

    if (!isWarningModalEnabled) return null;
    return (
        <LogoutWarningModal
            description="Your session is about to expire. Move your mouse to extend your session."
            isVisible={showWarning}
            showActions={false} // mouse movement extends session so no need to show actions
        />
    );
};

export default UserActivityWatcher;
