import React, { useEffect, useContext, useState } from 'react';
import { setCurrentUser, DefaultPublisher, CurrentUserSetEvent } from '@samc/common';
import CurrentUser from '../../models/CurrentUser';
import { useClient } from '../ClientContext/ClientContext';
import { TermsOfUseAcceptedEvent } from '../../events/TermsOfUseAcceptedEvent';

const UserContext = React.createContext<CurrentUser | undefined>(undefined);

export const CurrentUserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const client = useClient();
    const [user, setUser] = useState(new CurrentUser());

    const loadUser = () => {
        setCurrentUser(null); // Wipe current user
        let isCanceled = false;
        if (client.isReady) {
            // User is authenticated and has a token, so we can get the current user's data.
            client.userInfo().then((userInfo) => {
                if (!userInfo?.email) {
                    // We fetched okta data but we don't have the full user data from auth yet - hold off on setting the user.
                    return;
                }
                const currentUser = new CurrentUser();
                currentUser.populate(userInfo);
                if (!isCanceled) {
                    setCurrentUser(currentUser);
                }
            });
        }
        return () => {
            isCanceled = true;
        };
    };
    useEffect(loadUser, [client]);

    useEffect(() => {
        const subscriptionId = DefaultPublisher.subscribe<CurrentUserSetEvent>(
            CurrentUserSetEvent.eventName,
            (evt: CurrentUserSetEvent) => {
                if (evt.currentUser) {
                    setUser(evt.currentUser as CurrentUser);
                }
            },
        );
        const termsAcceptanceSubscriptionId = DefaultPublisher.subscribe<TermsOfUseAcceptedEvent>(
            TermsOfUseAcceptedEvent.eventName,
            loadUser,
        );
        return () => {
            DefaultPublisher.unsubscribe(CurrentUserSetEvent.eventName, subscriptionId);
            DefaultPublisher.unsubscribe(TermsOfUseAcceptedEvent.eventName, termsAcceptanceSubscriptionId);
        };
    });

    // Authorize was built on assuming CurrentUser can never be null, thus CurrentUserContext only shows children when user has a value.
    if (!user) return null;
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export function useCurrentUser(): CurrentUser {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useCurrentUser must be used within a CurrentUserProvider!');
    }
    return context;
}
