import React, { useContext } from 'react';
import { AuthorizationConfig } from '../../models/AuthorizationConfig';

const AuthorizationConfigContext = React.createContext<AuthorizationConfig | undefined>(undefined);
type AuthProps = React.PropsWithChildren<{
    config: AuthorizationConfig;
}>;

function AuthorizationConfigProvider({ config, children }: AuthProps): React.ReactElement {
    return <AuthorizationConfigContext.Provider value={config}>{children}</AuthorizationConfigContext.Provider>;
}

function useAuthorizationConfig(): AuthorizationConfig {
    const context = useContext(AuthorizationConfigContext);
    if (context === undefined) {
        throw new Error('useAuthorizationConfig must be used within an AuthorizationConfigProvider!');
    }
    return context;
}

export { AuthorizationConfigProvider, useAuthorizationConfig };
