/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties } from 'react';
import './Modal.css';
import { useAuthenticationConfig } from '../../contexts/AuthenticationConfigContext/AuthenticationConfigContext';

interface BaseProps {
    description?: string;
    isVisible: boolean;
    title: string;
}

type ModalProps = BaseProps &
    (
        | {
              showButtons: true;
              firstOptionText: string;
              firstOptionHandler: React.MouseEventHandler<HTMLDivElement>;
              secondOptionText: string;
              secondOptionHandler: React.MouseEventHandler<HTMLDivElement>;
          }
        | {
              showButtons?: false;
          }
    );

const Modal: React.FC<ModalProps> = (props) => {
    const { description, showButtons, isVisible, title } = props;

    const config = useAuthenticationConfig();
    const wrapperStyle =
        config.modalsZIndex === undefined || config.modalsZIndex === null
            ? {}
            : ({ zIndex: config.modalsZIndex } as CSSProperties);

    let buttonArea: React.ReactNode = null;
    if (showButtons) {
        const { firstOptionHandler, firstOptionText, secondOptionHandler, secondOptionText } = props;
        buttonArea = (
            <div className="actions space-x-4">
                <div className="grow" />
                <div className="button" onClick={firstOptionHandler}>
                    {firstOptionText}
                </div>
                <div className="button" onClick={secondOptionHandler}>
                    {secondOptionText}
                </div>
            </div>
        );
    }

    return (
        <div data-auth-okta className={`wrapper ${isVisible ? '' : 'disabled'}`} style={wrapperStyle}>
            <div className="overlay" />
            <div className="alert">
                <div className="header">
                    <div className="content">
                        <div className="title">{title}</div>
                        {description && <div className="description">{description}</div>}
                    </div>
                </div>
                {buttonArea}
            </div>
        </div>
    );
};

export default Modal;
