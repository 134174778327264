import React, { PropsWithChildren } from 'react';
import './global.css';
import { ClientConfiguration, useTenantId } from '@samc/single-spa-client-configuration';
import { TokenButton } from './atoms/TokenButton/TokenButton';
import { AuthorizationWrapper } from './organisms/AuthorizationWrapper/AuthorizationWrapper';
import { RequireTermsOfUse } from './organisms/RequireTermsOfUse/RequireTermsOfUse';
import { AuthenticationConfig } from './models/AuthenticationConfig';
import { AuthenticationWrapper } from './organisms/AuthenticationWrapper/AuthenticationWrapper';
import { UserActivityWatcher } from './molecules/UserActivityWatcher/UserActivityWatcher';
import { OktaAuthManager } from './utilities/OktaAuthManager/OktaAuthManager';
import { useToken } from './hooks/useToken/useToken';

const InnerRoot = (props: PropsWithChildren): React.ReactElement | null => {
    const { children } = props;

    const token = useToken();
    const tenantId = useTenantId();

    const wrapperConfig = React.useMemo((): ClientConfiguration | undefined => {
        const clientConfiguration = OktaAuthManager.getClientConfiguration();
        const { defaultHeaders: _inputDefaultHeaders = [] } = clientConfiguration;

        const defaultHeaders = [..._inputDefaultHeaders];
        if (tenantId)
            defaultHeaders.push({
                name: 'X-Tenant',
                value: tenantId,
            });

        return {
            ...clientConfiguration,
            defaultHeaders,
        };
    }, [tenantId]);

    if (!wrapperConfig) return null;
    return (
        <AuthorizationWrapper config={wrapperConfig} jwt={token}>
            {/** We do not want to show TOU until a user enters a tenant */}
            {tenantId && <RequireTermsOfUse />}
            {children}
        </AuthorizationWrapper>
    );
};

export const Root: React.FC = () => {
    const clientConfiguration = React.useMemo(() => {
        const _clientConfiguration = OktaAuthManager.getClientConfiguration();
        return {
            ..._clientConfiguration,
            useOktaUser: false,
            modalsZIndex: '300000',
        };
    }, []);

    return (
        <AuthenticationWrapper config={clientConfiguration as AuthenticationConfig}>
            <InnerRoot>
                <UserActivityWatcher />
                <TokenButton />
            </InnerRoot>
        </AuthenticationWrapper>
    );
};

export default Root;
