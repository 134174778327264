import React from 'react';
import { MessageBus } from '@samc/message-bus';
import './AuthenticationWrapper.css';
import { Security } from '@okta/okta-react';
import { Router } from '../../router/Router';
import { AuthenticationConfig } from '../../models/AuthenticationConfig';
import { AuthenticationConfigProvider } from '../../contexts/AuthenticationConfigContext/AuthenticationConfigContext';
import { MessageBusProvider } from '../../contexts/MessageBusContext/MessageBusContext';
import { OktaAuthManager } from '../../utilities/OktaAuthManager/OktaAuthManager';
import { useToken } from '../../hooks/useToken/useToken';

// TODO: token refresh on interaction

export type AuthenticationWrapperProps = React.PropsWithChildren<{
    config: AuthenticationConfig;
}>;

/**
 * A wrapper designated to be used as a single authority for instantiating the providers needed to
 * manage the login/logout and token validity of the application. It is expected to be rendered at the root
 * of the SPA.
 */
export const AuthenticationWrapper: React.FC<AuthenticationWrapperProps> = ({ config, children }) => {
    const oktaAuth = OktaAuthManager.getInstance();

    const jwt = useToken();

    const forwardedConfig = React.useMemo<AuthenticationConfig>(
        () => ({ ...config, client: { ...config.client, jwt: jwt ?? null } }),
        [config, jwt],
    );

    return (
        <div id="auth">
            <MessageBusProvider bus={new MessageBus()}>
                <AuthenticationConfigProvider config={forwardedConfig}>
                    <Security
                        oktaAuth={oktaAuth}
                        restoreOriginalUri={() => {
                            // pass, this is unused due to special return logic
                        }}
                    >
                        <Router>{children}</Router>
                    </Security>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Noto+Sans&amp;family=Roboto&amp;display=swap"
                        rel="stylesheet"
                    />
                </AuthenticationConfigProvider>
            </MessageBusProvider>
        </div>
    );
};

export default AuthenticationWrapper;
