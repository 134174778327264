/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { PrettyCenteredBox } from '../../atoms/PrettyCenteredBox/PrettyCenteredBox';
import { useAuthenticationConfig } from '../../contexts/AuthenticationConfigContext/AuthenticationConfigContext';

const LoggedOut: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const log = (window as any).$log || console; // use centricity client logging if available.
    log.info(`loading loggedout page`, 'Component: Auth');

    const { appName } = useAuthenticationConfig();

    const login = (): void => {
        log.debug('redirection to login', 'Component: Auth');
        window.location.href = '/login';
    };

    return (
        <PrettyCenteredBox>
            <p className="mt-xl font-bold text-2">You are now logged out of {appName}.</p>
            <p className="text-2">
                To log back in, please{' '}
                <a href="#" className="text-blue-5 underline" onClick={login}>
                    click here to log in
                </a>
                .
            </p>
        </PrettyCenteredBox>
    );
};

export default LoggedOut;
