import { AuthenticationConfig } from '../../models/AuthenticationConfig';
import { useLocalStorage } from '../useLocalStorage';

export const AUTHENTICATION_CONFIG_KEY = '@samc/single-spa-authentication__authenticationConfig';

export const useAuthenticationConfigFromStorage = (): AuthenticationConfig | null => {
    const [config] = useLocalStorage(AUTHENTICATION_CONFIG_KEY);

    return config as AuthenticationConfig;
};

export default useAuthenticationConfigFromStorage;
