/* eslint-disable no-console */
import { SecureRoute } from '@okta/okta-react';
import React from 'react';
import { Route, Switch, Router as CustomRouter } from 'react-router-dom';
import Login from '../pages/Login/Login';
import Logout from '../pages/Logout/Logout';
import LoggedOut from '../pages/LoggedOut/LoggedOut';
import { OktaAuthManager } from '../utilities/OktaAuthManager/OktaAuthManager';

export interface RouterProps {
    /**
     * What to render anywhere outside of the defined routes (login, logout, loggedOut) when the user is authenticated.
     */
    children: React.ReactNode;
}

/**
 * The router for the application. This will render the login, logout, and loggedOut routes, as well as the children when the user is authenticated.
 * Must be rendered beneath {@link OktaManager}.
 */
export const Router: React.FC<RouterProps> = ({ children }) => {
    const browserHistory = OktaAuthManager.getHistory();

    /**
     * In the scenario where a token refresh fails or is intentionally missed, we don't want to force users
     * back to login. Instead, we want to send them to logout so they can re-authenticate.
     */
    const onAuthRequired = (): void => {
        if (!OktaAuthManager.hasBeenAuthenticated()) {
            console.debug('Router: User was authenticated but is no longer. Logging out.');
            OktaAuthManager.logout();
        } else {
            console.debug('Router: User is not authenticated. Redirecting to login.');
            browserHistory.push('/login');
        }
    };

    return (
        <CustomRouter history={browserHistory}>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/loggedOut" component={LoggedOut} />
                <SecureRoute onAuthRequired={onAuthRequired} path="/*">
                    {children}
                </SecureRoute>
            </Switch>
        </CustomRouter>
    );
};
