import { AuthState } from '@okta/okta-auth-js';
import { BehaviorSubject } from 'rxjs';

// Creating subjects to mutate data over time
const authStateSubject = new BehaviorSubject<AuthState | null>(null);

// Defining observables from subjects to being able to subscribe to change updates.
export const userAuthState$ = authStateSubject.asObservable();

/**
 *
 * Indicates if the user has been authenticated by triggering the observer next() method.
 * Must have been subscribed to the userAuthState$ observable to listen to changes.
 * @param state - receives an object or null assignment
 */
export const emitUserAuthState = (state: AuthState | null): void => authStateSubject.next(state);
