import React from 'react';
import { ClientConfiguration } from '@samc/single-spa-client-configuration';
import { AuthorizationConfig } from '../../models/AuthorizationConfig';
import { AuthorizationConfigProvider } from '../../contexts/AuthorizationConfigContext/AuthorizationConfigContext';
import { ClientProvider } from '../../contexts/ClientContext/ClientContext';
import { CurrentUserProvider } from '../../contexts/CurrentUserContext/CurrentUserContext';
import { ValidUserCheck } from '../../molecules/ValidUserCheck/ValidUserCheck';

export interface AuthorizationWrapperProps {
    config: ClientConfiguration;
    jwt?: string | null;
    children?: React.ReactNode;
}

const fieldsRequiredInAuthorizationConfig = [
    'agGridLicense',
    'apiUrl',
    'appName',
    'authenticationScheme',
    'contactEmail',
    'helpLink',
    'platformName',
    'requireTermsOfUseAcceptance',
];

const isValidAuthorizationConfig = (config: Partial<ClientConfiguration>): config is AuthorizationConfig =>
    fieldsRequiredInAuthorizationConfig.every((f) => Reflect.get(config, f) !== undefined);

type AuthorizationProps = React.PropsWithChildren<{
    config: ClientConfiguration;
    jwt?: string | null;
}>;

export const AuthorizationWrapper: React.FC<AuthorizationProps> = ({ config, jwt, children }) => {
    const [gracePeriodActive, setGracePeriodActive] = React.useState(true);

    React.useEffect(() => {
        const timer = setTimeout(() => setGracePeriodActive(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    if (!isValidAuthorizationConfig(config)) {
        if (!gracePeriodActive)
            // eslint-disable-next-line no-console
            console.error('Cannot render Authorization due to missing required configuration values', {
                missingFields: fieldsRequiredInAuthorizationConfig.filter((f) => Reflect.get(config, f) === undefined),
                providedConfig: config,
            });
        // Do not render children since anything expecting a context provider will break
        return <div id="bad_authorization_config" />;
    }

    const components = (
        <AuthorizationConfigProvider config={config}>
            <ClientProvider jwt={jwt}>
                <CurrentUserProvider>
                    <ValidUserCheck>{children}</ValidUserCheck>
                </CurrentUserProvider>
            </ClientProvider>
        </AuthorizationConfigProvider>
    );
    return config.setContainerId ? <div id="auth">{components}</div> : components;
};

export default AuthorizationWrapper;
