import React from 'react';
import Modal from '../../atoms/Modal/Modal';

interface BaseProps {
    description: string;
    isVisible: boolean;
}

type LogoutWarningModalProps = BaseProps &
    (
        | {
              showActions: true;
              handleLogout: React.MouseEventHandler<HTMLDivElement>;
              handleStayConnected: React.MouseEventHandler<HTMLDivElement>;
          }
        | {
              showActions?: false;
          }
    );

const LogoutWarningModal: React.FC<LogoutWarningModalProps> = (props) => {
    const { description, isVisible, showActions } = props;

    if (showActions) {
        const { handleLogout, handleStayConnected } = props;

        return (
            <Modal
                title="Are you still there?"
                showButtons
                description={description}
                firstOptionText="Logout"
                firstOptionHandler={handleLogout}
                isVisible={isVisible}
                secondOptionText="Stay Connected"
                secondOptionHandler={handleStayConnected}
            />
        );
    }

    return <Modal title="Are you still there?" description={description} isVisible={isVisible} />;
};

export default LogoutWarningModal;
