// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../.yarn/__virtual__/css-loader-virtual-b14e297f85/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../.yarn/__virtual__/css-loader-virtual-b14e297f85/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../.yarn/__virtual__/css-loader-virtual-b14e297f85/0/cache/css-loader-npm-7.1.1-25b990b98a-586e8d2d38.zip/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/ProximaNova-RegularWeb.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"Proxima Nova\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  }\n\n#\\@samc\\/single-spa-authentication #auth  {\n    font-family: @apply font-roboto;\n}", "",{"version":3,"sources":["webpack://./src/organisms/AuthenticationWrapper/AuthenticationWrapper.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4CAAmD;EACrD;;AAEF;IACI,+BAA+B;AACnC","sourcesContent":["@font-face {\n    font-family: \"Proxima Nova\";\n    src: url(\"../../fonts/ProximaNova-RegularWeb.woff\");\n  }\n\n#auth  {\n    font-family: @apply font-roboto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
