/* eslint-disable default-case-last */
import { LoginRedirectStrategy } from '../../models/AuthenticationConfig';

const lastAccessedPathKey = 'auth-authentication-lastPath';

interface LoginRedirectOptions {
    loginRedirectStrategy: LoginRedirectStrategy;
}

export function getLoginRedirectPath(defaultLoginPath?: string, options?: LoginRedirectOptions): string {
    const { loginRedirectStrategy } = options ?? {};

    switch (loginRedirectStrategy) {
        case 'ToLastVisitedUrl':
        default: {
            const path = sessionStorage.getItem(lastAccessedPathKey);
            if (path) return path;
        }
        // allow falling through
        // eslint-disable-next-line no-fallthrough
        case 'ToLoginRedirectOnly':
            return defaultLoginPath ?? '/';
    }
}

export function saveLastAccessedPath(path: string): void {
    sessionStorage.setItem(lastAccessedPathKey, path);
}

export function clearLastAccessedPath(): void {
    sessionStorage.removeItem(lastAccessedPathKey);
}
