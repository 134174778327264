import React from 'react';
import './Error.css';
import logo from '../../images/situsamc.png';
import { useAuthorizationConfig } from '../../contexts/AuthorizationConfigContext/AuthorizationConfigContext';

type ErrorProps = {
    title: string;
    description?: string;
};

export const Error: React.FC<ErrorProps> = ({ title, description }) => {
    const authConfig = useAuthorizationConfig();

    return (
        <div id="bigProblem">
            <div className="main bg-blue-7 text-mono-1">
                <img src={logo} alt="" width="200" />
                <h3 className="font-noto">{title}</h3>
                {description && <p className="text-2">{description}</p>}
                <hr />
                <div className="links text-2">
                    <a href={authConfig.helpLink}>Help</a>|<a href={`mailto:${authConfig.contactEmail}`}>Contact Us</a>
                </div>
            </div>
        </div>
    );
};

export default Error;
