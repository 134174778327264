import React, { useEffect } from 'react';
import { AuthorizationClient as Client } from '../../clients/AuthorizationClient';
import { useAuthorizationConfig } from '../AuthorizationConfigContext/AuthorizationConfigContext';

const ClientContext = React.createContext<Client | undefined>(undefined);

type ClientProviderProps = React.PropsWithChildren<{
    jwt?: string | null;
}>;

export const ClientProvider: React.FC<ClientProviderProps> = ({ jwt, children }) => {
    const { apiUrl, defaultHeaders } = useAuthorizationConfig();
    const [client, setClient] = React.useState(new Client(apiUrl, undefined, defaultHeaders));

    const defaultHeaderRef = React.useRef(defaultHeaders);
    defaultHeaderRef.current = defaultHeaders; // so things don't get stale

    useEffect(() => {
        if (jwt) {
            const newClient = new Client(apiUrl, jwt, () => defaultHeaderRef.current ?? []);
            setClient(newClient);
        }
    }, [apiUrl, jwt]);

    return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>;
};

export function useClient(): Client {
    const context = React.useContext(ClientContext);
    if (context === undefined) {
        throw new Error('useClient must be used within a ClientProvider!');
    }
    return context;
}
