import { Guid } from '@samc/common';
import { parseDate } from '../utilities/parseDate/parseDate';
import Base from './Base';

export interface CompanyEditRequest {
    name: string;
}

export class Company extends Base<Company> {
    isActive: boolean = false;

    clarityId: number | null;

    updated: Date;

    updatedBy: Guid;

    constructor(id?: Guid, name?: string) {
        super(id, name);
        this.updated = new Date(0);
        this.updatedBy = Guid.createEmpty();
        this.clarityId = null;
    }

    isDirty(original: Company | null): boolean {
        return !original || this.name !== original.name;
    }

    /** Probably shouldn't be used for now; Company has been updated to look like
     * Role and User should we ever allow more direct management of the Companies
     * under the hood, but at present you can only edit a Company for one user at
     * a time and edits won't reflect on others. See */
    getDirtyFields(original: Company): string[] {
        if (!(original instanceof Company)) {
            return [];
        }

        const result = [];

        if (this.name !== original.name) {
            result.push('name');
        }

        return result;
    }

    clone(): Company {
        const clone = new Company(this.id, this.name);
        clone.isSystemControlled = this.isSystemControlled;
        return clone;
    }

    toEditRequest(): CompanyEditRequest {
        return {
            name: this.name,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromJson(json: any): Company {
        const company = new Company(Guid.parse(json.id), json.name);
        company.updated = parseDate(json.lastUpdated, company.updated);
        company.updatedBy = json.lastUpdatedBy ? Guid.parse(json.lastUpdatedBy) : Guid.createEmpty();
        company.isSystemControlled = json.isSystemControlled;
        company.clarityId = json.clarityId;
        return company;
    }
}

export default Company;
