import { UserClaims } from '@okta/okta-auth-js';
import { BehaviorSubject } from 'rxjs';

// Creating subjects to mutate data over time
const userSubject = new BehaviorSubject<UserClaims | null>(null);

// Defining observables from subjects to being able to subscribe to change updates.
export const user$ = userSubject.asObservable();

/**
 *
 * Updates the user data subject by triggering the observer next() method.
 * Must have been subscribed to the user$ observable to listen to changes.
 * @param user - receives a object or a null assignment
 */
export const emitUser = (user: UserClaims | null): void => userSubject.next(user);
