import React from 'react';
import './TokenButton.css';
import { useTokenFromStorage } from '../../hooks/useTokenFromStorage';

export const TokenButton = (): JSX.Element | null => {
    const token = useTokenFromStorage();
    const isVisible = React.useRef(localStorage.getItem('devtools') === 'true').current;

    const [tokenCopied, setTokenCopied] = React.useState(false);

    React.useEffect(() => {
        if (!tokenCopied) return;

        setTimeout(() => setTokenCopied(false), 2000);
    }, [tokenCopied]);

    if (!token || !isVisible) return null;

    return (
        <button
            type="button"
            className="copy-token-button"
            onClick={(): Promise<void> => navigator.clipboard.writeText(token).then(() => setTokenCopied(true))}
        >
            <div style={{ fontSize: '10px' }}>{tokenCopied ? 'Copied' : 'Copy Token'}</div>
        </button>
    );
};

export default TokenButton;
