import React from 'react';
import { Error } from '../../atoms/Error/Error';
import { useAuthorizationConfig, useCurrentUser } from '../../contexts';
import { Patience } from '../../atoms/Patience/Patience';

export const ValidUserCheck: React.FC<React.PropsWithChildren> = ({ children }) => {
    const user = useCurrentUser();
    const config = useAuthorizationConfig();

    if (user.isLoaded) {
        if (!user.exists) {
            return (
                <Error
                    title={`Not in ${config.appName}`}
                    description="You have not been added to this application. Please see your administrator for details."
                />
            );
        }
        if (user.isDisabled || (user.entitlements.length === 0 && config.showErrorForUserWithNoAccess)) {
            return (
                <Error
                    title="No Access Available"
                    description="You do not have access to this system. Please see your administrator for details."
                />
            );
        }
    }

    const showLoadingMesssage = !config.suppressUserLoadingMessage && !user.isLoaded;

    return <Patience showPatience={showLoadingMesssage}>{children}</Patience>;
};

export default ValidUserCheck;
