import { Guid } from '@samc/common';
import Methods from '../Methods';
import { Role } from '../../models/Role';
import { User } from '../../models/User';
import { ValidationErrors } from '../../models/ValidationErrors';

export class RoleClient {
    private methods: Methods;

    constructor(methods: Methods) {
        this.methods = methods;
    }

    async listRoles(): Promise<Role[]> {
        const response = await this.methods.get('role');
        const json = await response.json();
        const roles = new Array<Role>();

        if (Array.isArray(json)) {
            (json as Array<unknown>).forEach((model) => {
                roles.push(Role.fromJson(model));
            });
        }

        return roles;
    }

    async downloadRolesExport(): Promise<void> {
        const type = 'application/vnd.ms-excel';
        await this.methods.download(
            await this.methods.get('role', [{ name: 'Accept', value: type }]),
            type,
            'Roles.xlsx',
        );
    }

    async getRole(id: Guid): Promise<[boolean, Role | null]> {
        const response = await this.methods.get(`role/${id.toString()}`);
        const success = await this.methods.handleErrors('Getting a role', response);
        if (!success) {
            return [false, null];
        }
        const json = await response.json();
        return [true, Role.fromJson(json)];
    }

    async listUsersForRole(id: Guid): Promise<[boolean, Array<User>]> {
        const response = await this.methods.get(`role/${id.toString()}/users`);
        const success = await this.methods.handleErrors('Getting users for role', response);
        const users = new Array<User>();

        if (success) {
            const asJson = await response.json();
            if (Array.isArray(asJson)) {
                (asJson as Array<unknown>).forEach((model) => {
                    users.push(User.fromJson(model));
                });
            }
        }
        return [success, users];
    }

    async createRole(
        role: Role,
        updateValidationErrors: (update: (errors: ValidationErrors) => void) => void,
    ): Promise<[boolean, Guid]> {
        const response = await this.methods.post('role', role.toCreateRequest());
        const success = await this.methods.handleErrors('Creating new role', response, updateValidationErrors);
        let id = Guid.createEmpty();
        if (success) {
            const idString = await response.text();
            id = Guid.parse(idString.replace(/['"]+/g, ''));
        }
        return [success, id];
    }

    async editRole(
        role: Role,
        updateValidationErrors: (update: (errors: ValidationErrors) => void) => void,
    ): Promise<boolean> {
        const id = role.id.toString();
        const response = await this.methods.put(`role/${id}`, role.toEditRequest());
        return this.methods.handleErrors(`Editing role with id ${id}`, response, updateValidationErrors);
    }

    async updateRoleEntitlements(
        roleId: Guid,
        addedEntitlements: Guid[],
        removedEntitlements: Guid[],
    ): Promise<boolean> {
        const results = await Promise.all([
            this.addRoleEntitlements(roleId, addedEntitlements),
            this.removeRoleEntitlements(roleId, removedEntitlements),
        ]);
        return results.every((r) => r);
    }

    async addRoleEntitlements(roleId: Guid, entitlementIds: Guid[]): Promise<boolean> {
        if (entitlementIds.length) {
            const response = await this.methods.patch(`role/${roleId.toString()}/entitlements`, {
                remove: false,
                entitlementIds: entitlementIds.map((r) => r.toString()),
            });
            return this.methods.handleErrors('Adding roles for user', response);
        }
        return true;
    }

    async removeRoleEntitlements(roleId: Guid, entitlementIds: Guid[]): Promise<boolean> {
        if (entitlementIds.length) {
            const response = await this.methods.patch(`role/${roleId.toString()}/entitlements`, {
                remove: true,
                entitlementIds: entitlementIds.map((r) => r.toString()),
            });
            return this.methods.handleErrors('Removing roles for user', response);
        }
        return true;
    }

    async deleteRole(roleId: Guid): Promise<boolean> {
        const response = await this.methods.delete(`role/${roleId.toString()}`);
        return this.methods.handleErrors('Deleting role', response);
    }
}

export default RoleClient;
