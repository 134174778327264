/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useAuthenticationConfig } from '../../contexts';
import { PrettyCenteredBox } from '../../atoms/PrettyCenteredBox/PrettyCenteredBox';
import { OktaAuthManager } from '../../utilities/OktaAuthManager/OktaAuthManager';

const Login: React.FC = () => {
    const { appName } = useAuthenticationConfig();

    // if a login is necessary, trigger it
    React.useEffect(() => {
        const timer = setTimeout(() => OktaAuthManager.login(), 3000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <PrettyCenteredBox>
            <p className="mt-xl font-bold text-2">To log in to {appName}, you will be redirected momentarily.</p>
            <p className="text-2">
                If you are not automatically redirected in 10 seconds, please{' '}
                <a href="#" className="text-blue-5 underline" onClick={(): Promise<void> => OktaAuthManager.login()}>
                    click here to log in
                </a>
                .
            </p>
        </PrettyCenteredBox>
    );
};

export default Login;
