import { Guid } from '@samc/common';
import Methods from '../Methods';
import { User } from '../../models/User';
import { UserSystemData } from '../../models/UserSystemData';
import { ValidationErrors } from '../../models/ValidationErrors';

export class UserClient {
    private methods: Methods;

    async listUsers(): Promise<User[]> {
        const response = await this.methods.get('user');
        await this.methods.handleErrors('Retrieving list of all users', response);
        const json = await response.json();
        const users = new Array<User>();

        if (Array.isArray(json)) {
            (json as Array<unknown>).forEach((model) => {
                users.push(User.fromJson(model));
            });
        }

        return users;
    }

    async downloadUsersExport(): Promise<void> {
        const type = 'application/vnd.ms-excel';
        await this.methods.download(
            await this.methods.get('user', [{ name: 'Accept', value: type }]),
            type,
            'Users.xlsx',
        );
    }

    async getUser(id: Guid): Promise<User> {
        const idString = id.toString();
        const response = await this.methods.get(`user/${idString}`);
        await this.methods.handleErrors(`Retrieving user with id ${idString}`, response);
        const json = await response.json();
        return User.fromJson(json);
    }

    async getSystemDataById(systemId: string): Promise<UserSystemData | undefined> {
        if (systemId) {
            const response = await this.methods.get(`user/systemData/byId/${systemId}`);
            await this.methods.handleErrors(`Retrieving any external data for user with id ${systemId}`, response);
            if (response.status === 200) {
                // Has something for me; if no data, will return 204
                const json = await response.json();
                return UserSystemData.fromJson(json);
            }
        }

        return undefined;
    }

    async getSystemDataByEmail(email: string): Promise<UserSystemData | undefined> {
        if (email) {
            const response = await this.methods.get(`user/systemData/byEmail/${email}`);
            await this.methods.handleErrors(`Retrieving any external data for user with email ${email}`, response);
            if (response.status === 200) {
                // Has something for me; if no data, will return 204
                const json = await response.json();
                return UserSystemData.fromJson(json);
            }
        }

        return undefined;
    }

    async createUser(
        user: User,
        updateValidationErrors: (update: (errors: ValidationErrors) => void) => void,
    ): Promise<[boolean, Guid]> {
        const response = await this.methods.post('user', user.toCreateRequest());
        const success = await this.methods.handleErrors('Creating new user', response, updateValidationErrors);
        let id = Guid.createEmpty();
        if (success) {
            const idString = await response.text();
            id = Guid.parse(idString.replace(/['"]+/g, ''));
        }
        return [success, id];
    }

    async editUser(
        user: User,
        updateValidationErrors: (update: (errors: ValidationErrors) => void) => void,
    ): Promise<boolean> {
        const id = user.id.toString();
        const response = await this.methods.put(`user/${id}`, user.toEditRequest());
        return this.methods.handleErrors(`Editing user with id ${id}`, response, updateValidationErrors);
    }

    async setDisabledUser(id: Guid, isDisabled: boolean): Promise<void> {
        const route = isDisabled ? `user/${id}/disable` : `user/${id}/enable`;
        const response = await this.methods.patch(route);
        await this.methods.handleErrors(`Editing user with id ${id}`, response);
    }

    async resetPassword(id: Guid): Promise<boolean> {
        const response = await this.methods.post(`user/${id.toString()}/resetPassword`);
        return this.methods.handleErrors('Resetting password for user', response);
    }

    async updateUserRoles(userId: Guid, addedRoles: Guid[], removedRoles: Guid[]): Promise<void> {
        await Promise.all([this.addRoles(userId, addedRoles), this.removeRoles(userId, removedRoles)]);
    }

    async addRoles(userId: Guid, roleIds: Guid[]): Promise<boolean> {
        if (roleIds.length) {
            const response = await this.methods.patch(`user/${userId.toString()}/roles`, {
                remove: false,
                roleIds: roleIds.map((r) => r.toString()),
            });
            return this.methods.handleErrors('Adding roles for user', response);
        }
        return true;
    }

    async removeRoles(userId: Guid, roleIds: Guid[]): Promise<boolean> {
        if (roleIds.length) {
            const response = await this.methods.patch(`user/${userId.toString()}/roles`, {
                remove: true,
                roleIds: roleIds.map((r) => r.toString()),
            });
            return this.methods.handleErrors('Removing roles for user', response);
        }
        return true;
    }

    async acceptTerms(): Promise<boolean> {
        const response = await this.methods.post('user/acceptTerms');
        if (response.status > 204) {
            // eslint-disable-next-line no-console
            console.error(`Code: ${response.status}. Error: ${response.statusText}`);
            return false;
        }
        return true;
    }

    constructor(methods: Methods) {
        this.methods = methods;
    }
}

export default UserClient;
