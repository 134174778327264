import React from 'react';
import { userToken$ } from '../../observables/TokenObservable';

export const useToken = (): string | null => {
    const [token, setToken] = React.useState<string | null>(null);

    React.useEffect(() => {
        const subscriptor = userToken$.subscribe((u) => {
            setToken(u);
        });
        return (): void => subscriptor.unsubscribe();
    }, []);

    return token;
};

export default useToken;
