import { User } from '../../models/User';
import Methods, { DefaultHeaders } from '../Methods';
import { UserClient } from '../UserClient';
import { RoleClient } from '../RoleClient';
import { EntitlementClient } from '../EntitlementClient';
import { CompanyClient } from '../CompanyClient';
import { ConfigClient } from '../ConfigClient';

export class AuthorizationClient {
    private methods: Methods;

    public readonly users: UserClient;

    public readonly roles: RoleClient;

    public readonly entitlements: EntitlementClient;

    public readonly company: CompanyClient;

    public readonly config: ConfigClient;

    get isReady(): boolean {
        return !!this.methods.jwt;
    }

    updateJwt(jwt: string): void {
        this.methods.jwt = jwt;
    }

    async userInfo(): Promise<User | null> {
        const response = await this.methods.get('jwt/userInfo');
        if (response.status === 403) {
            return null; // user isn't in system; return null to let the caller know the user is nonexistent.
        }
        // Otherwise, it's either successful and we handle the JSON, or it's a 500 so we let it explode.
        await this.methods.handleErrors('Retrieving info for current user', response);
        const json = await response.json();
        return User.fromJson(json);
    }

    async refreshUserInfo(): Promise<void> {
        await this.methods.post('jwt/refreshUserInfo');
    }

    constructor(url: string, jwt?: string, defaultHeaders?: DefaultHeaders) {
        this.methods = new Methods(`${url}/api/v2`, jwt ?? '', defaultHeaders);
        this.users = new UserClient(this.methods);
        this.roles = new RoleClient(this.methods);
        this.entitlements = new EntitlementClient(this.methods);
        this.company = new CompanyClient(this.methods);
        this.config = new ConfigClient(this.methods);
    }
}

export default AuthorizationClient;
