import { ICurrentUser, Guid } from '@samc/common';
import { User } from './User';

export default class CurrentUser implements ICurrentUser {
    /** The full name of the user, most likely their first and last name combined. For token-based users, will be the username instead. */
    fullName: string;

    firstName: string;

    lastName: string;

    username: string;

    company: string;

    companyClarityId: number | null;

    email: string;

    id: Guid;

    systemId: string;

    exists: boolean;

    isDisabled: boolean;

    isLoaded: boolean;

    termsOfUseAcceptance: boolean = false;

    termsOfUseAcceptanceDate: Date | null = null;

    entitlements: string[];

    entitlementsByReferenceId: Guid[] = [];

    /** fullName and name are the same - the definition in samc/common is name, but I decided to provide better clarity here in the Auth package. */
    get name(): string {
        return this.fullName;
    }

    populate(user: User | null): void {
        this.isLoaded = true;
        // Null user means they don't have an account in the system. We bail because they're going to get an error and default values are fine.
        if (user === null) {
            this.exists = false;
            return;
        }
        this.fullName = user.name;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.username = user.username;
        this.company = user.company?.name ?? '---';
        this.email = user.email;
        this.id = user.id;
        this.systemId = user.systemId;
        this.isDisabled = user.isDisabled;
        this.termsOfUseAcceptance = user.termsOfUseAcceptance;
        this.termsOfUseAcceptanceDate = user.termsOfUseAcceptanceDate;
        // woah: https://stackoverflow.com/a/9229821
        this.entitlements = [...new Set(user.roles.flatMap((r) => r.entitlements.map((e) => e.name)))];
        this.entitlements.concat(user.entitlements.map((e) => e.name));
        this.entitlementsByReferenceId = user.roles.flatMap((r) =>
            r.entitlements
                .filter((e) => e.referenceId !== undefined && e.referenceId !== null)
                .map((e) => e.referenceId!),
        );
        this.entitlementsByReferenceId.concat(
            user.entitlements
                .filter((e) => e.referenceId !== undefined && e.referenceId !== null)
                .map((e) => e.referenceId!),
        );
        this.companyClarityId = user.company?.clarityId || null;
    }

    hasEntitlement(entitlement: string): boolean {
        return this.entitlements.some((e) => e === entitlement);
    }

    hasEntitlementWithReferenceId(entitlementReferenceId: Guid): boolean {
        return this.entitlementsByReferenceId.some((e) => e === entitlementReferenceId);
    }

    constructor() {
        this.fullName = '';
        this.firstName = '';
        this.lastName = '';
        this.company = '';
        this.email = '';
        this.username = '';
        this.entitlements = [];
        this.exists = true;
        this.isDisabled = false;
        this.isLoaded = false;
        this.id = Guid.createEmpty();
        this.systemId = '';
        this.companyClarityId = null;
    }
}
