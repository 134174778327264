/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useCurrentUser } from '../../contexts/CurrentUserContext/CurrentUserContext';

interface UnentitledCheckboxProps {
    label?: string;
    classNames?: string;
    value: boolean;
    setValue: (val: boolean) => void;
    readonly?: boolean;
}

interface EntitledCheckboxProps extends Omit<UnentitledCheckboxProps, 'readonly'> {
    entitlement: string;
}

export interface CheckboxProps extends UnentitledCheckboxProps {
    entitlement?: string;
}

const UnentitledCheckbox: React.FC<UnentitledCheckboxProps> = (props) => {
    const { label, classNames, value, setValue, readonly: isReadOnly = false } = props;

    let classes = isReadOnly ? 'text-mono-8 p-1 text-2' : 'cursor-pointer text-blue-7 p-1 text-2';
    if (classNames) {
        classes = `${classes} ${classNames}`;
    }
    const click = isReadOnly
        ? undefined
        : (): void => {
              setValue(!value);
          };
    if (label) {
        return (
            <span className={classes} onClick={click}>
                <span className={value ? 'mdi mdi-checkbox-marked' : 'mdi mdi-checkbox-blank-outline'} />
                <span className="ml-xs select-none">{label}</span>
            </span>
        );
    }
    classes = value ? `${classes} mdi mdi-checkbox-marked` : `${classes} mdi mdi-checkbox-blank-outline`;
    return <span className={classes} onClick={click} />;
};

const EntitledCheckbox: React.FC<EntitledCheckboxProps> = (props) => {
    const { entitlement } = props;

    const currentUser = useCurrentUser();
    const isReadOnly = currentUser.hasEntitlement(entitlement);

    return <UnentitledCheckbox {...props} readonly={isReadOnly} />;
};

export const Checkbox: React.FC<CheckboxProps> = (props) => {
    const { entitlement, readonly = false } = props;

    if (readonly || !entitlement) return <UnentitledCheckbox {...props} />;
    return <EntitledCheckbox {...props} entitlement={entitlement} />;
};

export default Checkbox;
