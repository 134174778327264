/* eslint-disable no-console */
import OktaAuth, { AuthState, Token, isAccessToken } from '@okta/okta-auth-js';
import { History } from 'history';
import { ClientConfiguration, getClientConfiguration } from '@samc/single-spa-client-configuration';
import { Guid, setCurrentUser } from '@samc/common';
import { emitUserToken } from '../../observables/TokenObservable';
import { emitUser } from '../../observables/UserObservable';
import { emitUserAuthState } from '../../observables/AuthStateObservable';
import { getLoginRedirectPath, saveLastAccessedPath } from '../loginRedirectPathHelpers/loginRedirectPathHelpers';
import { LoginRedirectMethod } from '../../models/AuthenticationConfig';
import { TOKEN_KEY } from '../../hooks/useTokenFromStorage';
import { AUTHENTICATION_CONFIG_KEY } from '../../hooks/useAuthenticationConfigFromStorage';
import { saveValueToLocalStorage } from '../../hooks/useLocalStorage/useLocalStorage';
import { fetchCookie, getOktaAuth } from './Utilities';

const PATH_SAVING_IGNORE_PATHS = ['/login', '/logout', '/loggedOut'];

interface OktaAuthManagerInitParams {
    /**
     * History used to navigate the application, falls back to window.location if not provided
     */
    browserHistory?: History;
    /**
     * Whether to allow reinitialization of the OktaAuthManager, defaults to false. Only use this if you know what you're doing, as it can cause unexpected behavior.
     * @default false
     */
    UNSAFE_allowReinitialize?: boolean;
}

interface CookieFetch {
    token: string;
    cookieUrl: string;
    promise: Promise<void>;
}

interface LogoutOptions {
    /**
     * Replaces the current URL in the history stack with the logout URL
     */
    replace?: boolean;
}

interface OktaAuthManagerConfiguration {
    /**
     * https://github.com/okta/okta-auth-js?tab=readme-ov-file#expireearlyseconds
     * suggests this is noop outside of a local environment, forced to 30s.
     * Do not change this value unless you know what you're doing.
     */
    expireEarlySeconds: number;
}

export class OktaAuthManager {
    private static _instance: OktaAuth | undefined;

    private static _history: History | undefined;

    private static _pendingCookieFetch: CookieFetch | undefined;

    private static _clientConfiguration: ClientConfiguration | undefined;

    /**
     * A list of callback functions that will deregister listeners when the OktaAuthManager is destroyed
     */
    private static _listenerDeregistrations: Array<() => void> = [];

    public static configuration: OktaAuthManagerConfiguration = {
        /**
         * https://github.com/okta/okta-auth-js?tab=readme-ov-file#expireearlyseconds
         * suggests this is noop outside of a local environment, forced to 30s.
         * Do not change this value unless you know what you're doing.
         */
        expireEarlySeconds: 30,
    };

    private static _hasBeenAuthenticated = false;

    /**
     * Gets the instance of the OktaAuthManager, throws an error if it's not initialized
     * @returns OktaAuth
     */
    public static getInstance(): OktaAuth {
        if (!this._instance) throw new Error('OktaAuthManager is not initialized');
        return this._instance;
    }

    public static getClientConfiguration(): ClientConfiguration {
        if (!this._clientConfiguration) throw new Error('OktaAuthManager is not initialized');
        return this._clientConfiguration;
    }

    public static getHistory(): History {
        if (!this._history) throw new Error('OktaAuthManager is not initialized');
        return this._history;
    }

    /**
     * Returns whether the user has been authenticated previously
     */
    public static hasBeenAuthenticated(): boolean {
        return this._hasBeenAuthenticated;
    }

    /**
     * Navigates to the specified URL using the specified method
     */
    private static navigate(href: string, method: LoginRedirectMethod, replace?: boolean): void {
        switch (method) {
            case 'RouterRedirect':
                if (!this._history) throw new Error('History is not initialized');
                if (replace) this._history.replace(href);
                else this._history.push(href);
                break;
            case 'HardRedirect':
            default:
                if (replace) window.location.replace(href);
                else window.location.assign(href);
        }
    }

    private static async afterLogin(_shouldRedirect: boolean): Promise<void> {
        const { loginRedirectPath, loginRedirectStrategy, cookieUrl, loginRedirectMethod } =
            this.getClientConfiguration();

        const instance = this.getInstance();
        this._hasBeenAuthenticated = true;

        // emit initial user
        const user = await instance.getUser();
        emitUser(user);

        // set current user, as a stop-gap
        setCurrentUser({
            id: Guid.createEmpty(),
            name: user.name ?? '',
            email: user.email ?? '',
            company: '',
            entitlements: new Array<string>(),
            entitlementsByReferenceId: new Array<Guid>(),
            hasEntitlement: () => true,
            hasEntitlementWithReferenceId: () => true,
            isDisabled: false,
            isLoaded: true,
        });

        // emit initial auth state
        emitUserAuthState(instance.authStateManager.getAuthState());

        // emit initial token
        const token = instance.getAccessToken();
        emitUserToken(token ?? null);

        let shouldRedirect = _shouldRedirect;

        // ensure cookie is up to date, if necessary
        if (cookieUrl && token) {
            if (loginRedirectMethod !== 'HardRedirect' && shouldRedirect) {
                shouldRedirect = false;
                console.warn('Cannot soft redirect after cookie update');
            }

            await this.getLatestCookie({ token, cookieUrl });
        }

        // redirect
        const redirectTo = getLoginRedirectPath(loginRedirectPath, {
            loginRedirectStrategy: loginRedirectStrategy ?? 'ToLastVisitedUrl',
        });
        if (shouldRedirect) this.navigate(redirectTo, loginRedirectMethod || 'HardRedirect', false);
    }

    /**
     * Retrieves the latest cookie from the cookie URL and saves it to the browser
     */
    private static async getLatestCookie(params: { token: string; cookieUrl: string }): Promise<void> {
        // use pending cookie fetch
        if (
            !this._pendingCookieFetch ||
            this._pendingCookieFetch.token !== params.token ||
            this._pendingCookieFetch.cookieUrl !== params.cookieUrl
        ) {
            // get latest cookie from cookie URL
            this._pendingCookieFetch = {
                token: params.token,
                cookieUrl: params.cookieUrl,
                promise: fetchCookie({ accessToken: params.token, cookieUrl: params.cookieUrl })
                    .catch(() => {
                        console.error('OktaAuthManager: Failed to update auth cookie');
                        this._pendingCookieFetch = undefined;
                    })
                    .then(() => console.debug('OktaAuthManager: Updated auth cookie')),
            };
        }

        return this._pendingCookieFetch.promise;
    }

    /**
     * Initializes the listeners for the OktaAuth instance
     * @param instance the OktaAuth instance
     */
    private static async initializeListeners(): Promise<void> {
        const instance = this.getInstance();
        const { cookieUrl } = this.getClientConfiguration();

        const onTokenUpdate = (_: string, token: Token): void => {
            if (isAccessToken(token)) {
                // emit via rxjs
                emitUserToken(token.accessToken);

                // save to local storage
                saveValueToLocalStorage(TOKEN_KEY, token.accessToken);
            }
        };

        const onAuthStateUpdate = (authState: AuthState): void => {
            // handle login redirects
            const previousAuthState = instance.authStateManager.getPreviousAuthState();
            const { isAuthenticated, accessToken } = authState;
            const { isAuthenticated: wasAuthenticated } = previousAuthState ?? {};

            // if we hard redirect here, it'll cause an infinite loop
            if (isAuthenticated && !wasAuthenticated) this.afterLogin(false);

            // redirect on logout
            if (!isAuthenticated && wasAuthenticated) this.logout({ replace: false });

            // update cookie and bearer token for token provider
            if (accessToken && cookieUrl) this.getLatestCookie({ token: accessToken.accessToken, cookieUrl });
        };

        instance.tokenManager.on('added', onTokenUpdate);
        instance.tokenManager.on('renewed', onTokenUpdate);

        instance.authStateManager.subscribe(emitUserAuthState);
        instance.authStateManager.subscribe(onAuthStateUpdate);

        // add listeners to deregistration list
        this._listenerDeregistrations.push(() => {
            instance.tokenManager.off('added', onTokenUpdate);
            instance.tokenManager.off('renewed', onTokenUpdate);
            instance.authStateManager.unsubscribe(emitUserAuthState);
            instance.authStateManager.unsubscribe(onAuthStateUpdate);
        });
    }

    private static beginRecordingPaths(): void {
        const { ignoredLastRedirectUrlMatchers, loginRedirectStrategy } = this.getClientConfiguration();

        if (loginRedirectStrategy !== 'ToLastVisitedUrl') return;

        const handler = (): void => {
            if (PATH_SAVING_IGNORE_PATHS.includes(window.location.pathname)) return;
            if (
                ignoredLastRedirectUrlMatchers &&
                ignoredLastRedirectUrlMatchers.some((m) => m.test(window.location.href))
            )
                return;

            saveLastAccessedPath(window.location.href);
        };

        handler(); // initial run
        setInterval(handler, 1000);
    }

    /**
     * Initializes the OktaAuthManager
     * @param params the initialization parameters
     */
    public static async initialize(params: OktaAuthManagerInitParams): Promise<void> {
        const { browserHistory, UNSAFE_allowReinitialize: allowReinit } = params;
        const { expireEarlySeconds } = this.configuration;

        // if there's already an instance, throw an error
        if (this._instance && !allowReinit) throw new Error('OktaAuthManager is already initialized');

        // load in config
        const clientConfiguration = await getClientConfiguration();
        this._clientConfiguration = clientConfiguration;

        // set history
        this._history = browserHistory;

        // save to local storage
        saveValueToLocalStorage(AUTHENTICATION_CONFIG_KEY, clientConfiguration);

        const { issuer, clientId, tokenRefreshMethod, baseUrl } = clientConfiguration;

        // validate
        if (!issuer || !clientId || !baseUrl) throw new Error('Invalid configuration');

        // create instance
        const oktaAuth = getOktaAuth({
            issuer,
            redirectUri: `${baseUrl}`,
            postLogoutRedirectUri: `${baseUrl}/loggedOut`,
            clientId,
            pkce: true,
            tokenManager: {
                autoRenew: tokenRefreshMethod === 'Auto',
                expireEarlySeconds,
            },
        });

        // set instance
        this._instance = oktaAuth;

        // startup, load tokens
        const needsRedirect = oktaAuth.isLoginRedirect();
        if (needsRedirect) {
            // handle loading tokens from URL
            await oktaAuth.token.parseFromUrl().then(
                ({ tokens }) => {
                    oktaAuth.tokenManager.setTokens(tokens);
                },
                (err) => console.error(err),
            );
        }

        // will emit initial events
        await oktaAuth.authStateManager.updateAuthState().then(
            async (r) => {
                if (r.isAuthenticated) await this.afterLogin(needsRedirect);
                return r;
            },
            (err) => {
                console.error(err);
                throw err;
            },
        );

        // add listeners
        this.initializeListeners();

        // start monitoring paths
        this.beginRecordingPaths();
    }

    /**
     * Initiates a logout of the user and ends the session
     */
    public static async logout(options?: LogoutOptions): Promise<void> {
        const { replace } = options ?? {};
        const instance = this.getInstance();
        const { cookieUrl } = this.getClientConfiguration();

        // clear listeners, otherwise they'll catch logout and bypass session closure
        while (this._listenerDeregistrations.length > 0) {
            const deregister = this._listenerDeregistrations.pop() as () => void;
            deregister();
        }

        // revoke the tokens, see why at https://github.com/okta/okta-auth-js?tab=readme-ov-file#closesession
        const promises = new Array<Promise<void>>();
        const { accessToken, refreshToken } = await instance.tokenManager.getTokens();
        if (accessToken) {
            promises.push(
                instance
                    .revokeAccessToken(accessToken)
                    .then(() => console.debug('OktaAuthManager: Revoked access token'))
                    .catch((err) => console.error('OktaAuthManager: Failed to revoke access token', err)),
            );
        }

        if (refreshToken) {
            promises.push(
                instance
                    .revokeRefreshToken(refreshToken)
                    .then(() => console.debug('OktaAuthManager: Revoked refresh token'))
                    .catch((err) => console.error('OktaAuthManager: Failed to revoke refresh token', err)),
            );
        }

        promises.push(
            instance
                .closeSession()
                .then(() => console.debug('OktaAuthManager: Closed session'))
                .catch((err) => console.error('OktaAuthManager: Failed to close session', err)),
        );

        // clear cookie
        if (cookieUrl) {
            promises.push(
                fetch(cookieUrl, { method: 'DELETE' })
                    .then(() => console.debug('OktaAuthManager: Deleted cookie'))
                    .catch(() => console.error('OktaAuthManager: Failed to delete cookie')),
            );
        }

        await Promise.all(promises);

        // redirect, must be hard to allow re-registration of listeners; a hard refresh is recommended, ergo hard redirect
        this.navigate('/loggedOut', 'HardRedirect', replace);
    }

    /**
     * Initiates a login of the user, redirecting to the Okta login page.
     * If the user is already logged in, they will be redirected to the appropriate page based on policy.
     */
    public static async login(): Promise<void> {
        const instance = this.getInstance();
        const isLoggedIn = await instance.isAuthenticated();

        if (isLoggedIn) {
            console.log('OktaAuthManager: User is already logged in, redirecting');
            const { loginRedirectPath, loginRedirectStrategy, loginRedirectMethod } = this.getClientConfiguration();

            const href = getLoginRedirectPath(loginRedirectPath, {
                loginRedirectStrategy: loginRedirectStrategy ?? 'ToLastVisitedUrl',
            });

            this.navigate(href, loginRedirectMethod || 'HardRedirect', false);
        } else {
            console.log('OktaAuthManager: User is not logged in, initiating login');
            await instance.signInWithRedirect();
        }
    }

    /**
     * Renews the tokens (id/access) for the user. Good for contexts where the token is not automatically renewed.
     */
    public static async renewTokens(): Promise<void> {
        const instance = this.getInstance();

        console.debug('OktaAuthManager: Renewing tokens');

        await instance.tokenManager.renew('idToken');
        await instance.tokenManager.renew('accessToken');
    }
}
