import React, { useEffect } from 'react';
import { useAuthenticationConfig } from '../../contexts/AuthenticationConfigContext/AuthenticationConfigContext';
import { PrettyCenteredBox } from '../../atoms/PrettyCenteredBox/PrettyCenteredBox';
import { OktaAuthManager } from '../../utilities/OktaAuthManager/OktaAuthManager';

const Logout: React.FC = () => {
    const { appName, returnToApplicationPath } = useAuthenticationConfig();

    useEffect(() => {
        const timer = setTimeout(() => OktaAuthManager.logout({ replace: true }), 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <PrettyCenteredBox>
            <p className="mt-xl font-bold text-2">You are being logged out of {appName}.</p>
            <p className="text-2">
                If you want to remain logged in,{' '}
                <a href={returnToApplicationPath ?? '/'} className="text-blue-5 underline">
                    click here
                </a>
                .
            </p>
        </PrettyCenteredBox>
    );
};

export default Logout;
