import Methods from '../Methods';
import { Entitlement } from '../../models/Entitlement';
import { groupBy } from '../../utilities/groupBy/groupBy';

export class EntitlementClient {
    private methods: Methods;

    constructor(methods: Methods) {
        this.methods = methods;
    }

    async listEntitlements(): Promise<Entitlement[]> {
        const response = await this.methods.get('entitlement');
        const json = await response.json();
        const entitlements = new Array<Entitlement>();
        let order = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const groupedJson = groupBy(json, (m: any) => m.group);
        // grouping them because the UI will later group them, and we want a consistent order value.
        groupedJson.forEach((items) => {
            entitlements.push(
                ...items.map((i) => {
                    const entitlement = Entitlement.fromJson(i, order);
                    order += 1;
                    return entitlement;
                }),
            );
        });

        return entitlements;
    }
}

export default EntitlementClient;
