import { DefaultHeaders, Header } from '../models/Headers';
import { ValidationErrors } from '../models/ValidationErrors';

export type { Header, DefaultHeaders } from '../models/Headers'; // backwards compat

export default class Methods {
    public url: string;

    public jwt: string;

    public defaultHeaders: DefaultHeaders | undefined;

    constructor(url: string, jwt: string, defaultHeaders?: DefaultHeaders) {
        this.url = url;
        this.jwt = jwt;
        this.defaultHeaders = defaultHeaders;
    }

    public get(route: string, addlHeaders: Array<Header> | null = null): Promise<Response> {
        return this.emptyRequest(route, 'GET', addlHeaders);
    }

    public post<TBody>(route: string, body: TBody | undefined = undefined): Promise<Response> {
        return body ? this.bodyRequest(route, 'POST', body) : this.emptyRequest(route, 'POST');
    }

    public put<TBody>(route: string, body: TBody): Promise<Response> {
        return body ? this.bodyRequest(route, 'PUT', body) : this.emptyRequest(route, 'PUT');
    }

    public patch<TBody>(route: string, body: TBody | undefined = undefined): Promise<Response> {
        return body ? this.bodyRequest(route, 'PATCH', body) : this.emptyRequest(route, 'PATCH');
    }

    public delete<TBody>(route: string, body: TBody | undefined = undefined): Promise<Response> {
        return body ? this.bodyRequest(route, 'DELETE', body) : this.emptyRequest(route, 'DELETE');
    }

    // Little bit of https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c,
    // Little bit of https://stackoverflow.com/a/59940621
    public async download(response: Response, type?: string, filename?: string): Promise<void> {
        const blob = await response.blob();
        const downloadableBlob = new Blob([blob], { type });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window.navigator as any).msSaveOrOpenBlob(downloadableBlob, filename);
        } else {
            const file = window.URL.createObjectURL(downloadableBlob);
            const link = document.createElement('a');
            link.href = file;
            if (filename) {
                link.download = filename;
            }
            link.click();
        }
    }

    public emptyRequest(route: string, method: string, addlHeaders: Array<Header> | null = null): Promise<Response> {
        return fetch(`${this.url}/${route}`, {
            method,
            headers: this.headers(addlHeaders),
        });
    }

    public bodyRequest<TBody>(
        route: string,
        method: string,
        body: TBody | null = null,
        addlHeaders: Array<Header> | null = null,
    ): Promise<Response> {
        return fetch(`${this.url}/${route}`, {
            method,
            headers: this.headers(addlHeaders),
            body: body !== null ? JSON.stringify(body) : null,
        });
    }

    public headers(addlHeaders: Array<Header> | null = null): Headers {
        const result = new Headers({
            Authorization: `Bearer ${this.jwt}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });

        let _defaultHeaderArray: Header[] = [];
        if (typeof this.defaultHeaders === 'function') _defaultHeaderArray = this.defaultHeaders();
        else if (this.defaultHeaders) _defaultHeaderArray = this.defaultHeaders;

        const headersToAdd = [..._defaultHeaderArray, ...(addlHeaders ?? [])];

        headersToAdd.forEach((h) => {
            result.set(h.name, h.value);
        });

        return result;
    }

    public async handleErrors(
        action: string,
        response: Response,
        updateValidationErrors?: (update: (errors: ValidationErrors) => void) => void,
    ): Promise<boolean> {
        if (response.ok || response.redirected) {
            return true;
        }
        const message = await response.text();
        if (response.status === 400) {
            // Validation failure
            console.warn(`Validation error for action "${action}"`);
            const errorJson = JSON.parse(message);
            console.warn(errorJson);
            if (updateValidationErrors) {
                updateValidationErrors((validationErrors) => {
                    Object.keys(errorJson).forEach((name) => {
                        const actualName = name.replace('Model.', '');
                        const errors = errorJson[name] as string[];
                        if (errors && errors.length > 0) {
                            validationErrors.addServerErrors(actualName, errors);
                        }
                    });
                });
            }
            return false;
        }
        const error = `Failure for action "${action}"`;
        console.error(error);
        console.error(`Response Status: ${response.status} - ${response.statusText}`);
        console.error(`Response Message: ${message}`);
        throw new Error(error);
    }
}
