import OktaAuth, { OktaAuthOptions } from '@okta/okta-auth-js';

/**
 * A helper function to create an OktaAuth instance, useful for testing
 */
export const getOktaAuth = (options: OktaAuthOptions): OktaAuth => new OktaAuth(options);

interface GetCookieOptions {
    accessToken: string;
    cookieUrl: string;
}

/**
 * Fetches a cookie from a given URL and sets it in the browser
 * @param options - receives an accessToken and a cookieUrl to fetch the cookie
 */
export const fetchCookie = async (options: GetCookieOptions): Promise<void> => {
    const { accessToken, cookieUrl } = options;

    await fetch(cookieUrl, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
};
