import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import singleSpaReact from 'single-spa-react';
import { createBrowserHistory } from 'history';
import { Root } from './root.component';
import { OktaAuthManager } from './utilities/OktaAuthManager/OktaAuthManager';

const spaElementId = '@samc/single-spa-authentication';

const domElementGetter = (): HTMLElement => {
    let el = document.getElementById(spaElementId);
    if (!el) {
        el = document.createElement('div');
        el.id = spaElementId;
        document.body.appendChild(el);
    }

    return el;
};

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    errorBoundary(_err, _info, _props) {
        // Customize the root error boundary for your microfrontend here.
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    },
    loadRootComponent: async () => {
        // initialize the auth manager
        await OktaAuthManager.initialize({ browserHistory: createBrowserHistory() });
        return Root;
    },
    domElementGetter,
});

export const { bootstrap, mount, unmount } = lifecycles;

// List any exports to expose to other microfrontends here.
export { useTokenFromStorage as useToken } from './hooks/useTokenFromStorage';
export { useAuthenticationConfigFromStorage as useAuthenticationConfig } from './hooks/useAuthenticationConfigFromStorage';
export { useUserAuthenticationState } from './hooks/useUserAuthState';
export { useUser } from './hooks/useUser';
export { userToken$ } from './observables/TokenObservable';
export { userAuthState$ } from './observables/AuthStateObservable';
export { user$ } from './observables/UserObservable';

export { AuthorizationWrapper } from './organisms/AuthorizationWrapper/AuthorizationWrapper';
export { Entitlements } from './Entitlements';

// Passthrough imports from other packages
export { useCurrentUser } from './contexts/CurrentUserContext/CurrentUserContext';
